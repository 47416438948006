<template>
  <div class="row">
    <div class="col-12">
      <div class="p-1 w-100 bg-white">
        <button type="button" class="btn btn-link p-0" @click="showInfo">
          <span class="font-weight-bold h5 mr-1 text-uppercase">Miracle Deal</span>
          <i class="fa fa-info-circle fa-lg text-info"></i>
        </button>
      </div>
    </div>
    <div class="col-12">
      <IntroBanner
        :slogan="$t('miracle_deal.intro_slogan')"
        :slogan2="$t('miracle_deal.intro_slogan2')"
        :strikerBoxText="$t('miracle_deal.intro_tesla_info_text')"
        :strikerThumbImgPath="strikerThumbImgPath"
        :strikerThumbName="'Nicola Tesla'"
        :keeperBoxText="$t('miracle_deal.intro_edison_info_text')"
        :keeperThumbImgPath="keeperThumbImgPath"
        :keeperThumbName="'Thomas Edison'"
      />
    </div>
    <DealModal v-if="infoModal" ref="infoModal" :title="$t('commons.important_info')">
      <template v-slot:body>
        Information about MIRACLE DEAL...
      </template>
    </DealModal>
  </div>
</template>

<script>
import IntroBanner from '@/components/miracle-deal/IntroBanner.vue';
import DealModal from '@/components/miracle-deal/DealModal.vue';
import { mapState } from 'vuex';
export default {
  name: 'DealIntroOne',
  components: {
    IntroBanner,
    DealModal
  },
  data() {
    return {
      strikerThumbImgPath: require('@/assets/images/deal/Tesla.png'),
      keeperThumbImgPath: require('@/assets/images/deal/Edison.png'),
    };
  },
  computed: {
    ...mapState({
      infoModal: (state) => state.global.dealModal
    }),
  },
  methods: {
    showInfo() {
      this.$store.commit('global/SET_DEAL_MODAL', true);
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
