var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card pt-1 shadow-none no-border-top-left-radius no-border-top-right-radius"},[_c('div',{staticClass:"polygonContainer"},[_c('div',{staticClass:"text-center pt-3 mx-auto width-30-per"},[_c('h1',{staticClass:"text-white text-uppercase"},[_vm._v(_vm._s(_vm.slogan))]),_c('h1',{staticClass:"text-white text-uppercase"},[_vm._v(_vm._s(_vm.slogan2))])])]),_c('div',{staticClass:"row justify-content-between zindex-1 px-5 skWrapper"},[_c('div',{staticClass:"col-md-4 col-lg-4 col-xl-4 col-sm-12 mb-3"},[_c('div',{staticClass:"row height-20-per"},[_c('h6',{staticClass:"infoTextBox"},[_vm._v(_vm._s(_vm.strikerBoxText))])]),_c('div',{staticClass:"d-flex flex-column mb-2 pull-left"},[_c('img',{staticClass:"responsive",staticStyle:{"max-width":"245px"},attrs:{"src":_vm.strikerThumbImgPath,"alt":_vm.strikerThumbName}}),_c('p',{staticStyle:{"margin-top":"-15%","margin-bottom":"-0.5%"}},[_vm._v(_vm._s(_vm.strikerThumbName))]),_c('button',{staticClass:"becomeSKBtnBase becomeStriker text-uppercase",on:{"click":function($event){return _vm.goToRouter('striker')}}},[_vm._v(" "+_vm._s(_vm.$t('miracle_deal.be_s_or_k', { s_or_k: 'Striker' }))+" ")])]),(_vm.showTopSKButtons)?_c('div',{staticClass:"bg-transparent text-center pull-left w-100 pb-1",style:({
          'background-image': `url(${_vm.triangleImg1Path})`,
          'background-position': 'top',
          'background-repeat': 'no-repeat',
          'background-size': '32px 45px'
        })},[_c('h4',{staticClass:"pt-1",staticStyle:{"color":"#77519c"}},[_vm._v("Top 3 Striker")]),_c('span',{staticClass:"font-weight-bold font-large-1",staticStyle:{"color":"#77519c"}},[_vm._v(" 1. 2. 3. ")]),_vm._l((_vm.topStrikers),function(item,idx){return _c('p',{key:`topStriker-${idx}-${item.id}`},[_c('b',[_vm._v("ID:")]),_vm._v(" "+_vm._s(item.id)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"col-md-4 col-lg-4 col-xl-4 col-sm-12 mb-3 text-right"},[_c('div',{staticClass:"row height-20-per"},[_c('h6',{staticClass:"infoTextBox"},[_vm._v(_vm._s(_vm.keeperBoxText))])]),_c('div',{staticClass:"d-flex flex-column mb-2 pull-right"},[_c('img',{staticClass:"responsive",staticStyle:{"max-width":"268px"},attrs:{"src":_vm.keeperThumbImgPath,"alt":_vm.keeperThumbName}}),_c('p',{staticStyle:{"margin-top":"-15%","margin-bottom":"-0.5%","margin-right":"-10%"}},[_vm._v(_vm._s(_vm.keeperThumbName))]),_c('button',{staticClass:"becomeSKBtnBase becomeKeeper text-uppercase",on:{"click":function($event){return _vm.goToRouter('keeper')}}},[_vm._v(" "+_vm._s(_vm.$t('miracle_deal.be_s_or_k', { s_or_k: 'Keeper' }))+" ")])]),(_vm.showTopSKButtons)?_c('div',{staticClass:"bg-transparent text-center pull-right w-100 pb-1",style:({
          'background-image': `url(${_vm.triangleImg2Path})`,
          'background-position': 'top',
          'background-repeat': 'no-repeat',
          'background-size': '32px 45px'
        })},[_c('h4',{staticClass:"pt-1",staticStyle:{"color":"#77519c"}},[_vm._v("Top 3 Keeper")]),_c('span',{staticClass:"font-weight-bold font-large-1",staticStyle:{"color":"#77519c"}},[_vm._v(" 1. 2. 3. ")]),_vm._l((_vm.topKeepers),function(item,idx){return _c('p',{key:`topKeeper-${idx}-${item.id}`},[_c('b',[_vm._v("ID:")]),_vm._v(" "+_vm._s(item.id)+" ")])})],2):_vm._e()])]),(_vm.showMdealLogo)?_c('img',{staticClass:"mdealLogo",attrs:{"src":_vm.mdealLogoPath}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }