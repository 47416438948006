<template>
  <div class="card pt-1 shadow-none no-border-top-left-radius no-border-top-right-radius">
    <!-- <div class="polygonContainer" :style="{ 'background-image': `url(${require('../../assets/images/deal/polygon.jpg')})` }"> -->
    <div class="polygonContainer">
      <div class="text-center pt-3 mx-auto width-30-per">
        <h1 class="text-white text-uppercase">{{ slogan }}</h1>
        <h1 class="text-white text-uppercase">{{ slogan2 }}</h1>
      </div>
    </div>
    <div class="row justify-content-between zindex-1 px-5 skWrapper">
      <div class="col-md-4 col-lg-4 col-xl-4 col-sm-12 mb-3">
        <div class="row height-20-per">
          <h6 class="infoTextBox">{{ strikerBoxText }}</h6>
        </div>
        <div class="d-flex flex-column mb-2 pull-left">
          <img class="responsive" style="max-width: 245px" :src="strikerThumbImgPath" :alt="strikerThumbName" />
          <p style="margin-top: -15%; margin-bottom: -0.5%">{{ strikerThumbName }}</p>
          <button class="becomeSKBtnBase becomeStriker text-uppercase" @click="goToRouter('striker')">
            {{ $t('miracle_deal.be_s_or_k', { s_or_k: 'Striker' }) }}
          </button>
        </div>
        <div
          class="bg-transparent text-center pull-left w-100 pb-1"
          v-if="showTopSKButtons"
          :style="{
            'background-image': `url(${triangleImg1Path})`,
            'background-position': 'top',
            'background-repeat': 'no-repeat',
            'background-size': '32px 45px'
          }"
        >
          <h4 style="color: #77519c" class="pt-1">Top 3 Striker</h4>
          <span class="font-weight-bold font-large-1" style="color: #77519c">
            1. 2. 3.
          </span>
          <p
            v-for="(item, idx) in topStrikers"
            :key="`topStriker-${idx}-${item.id}`"
          >
            <b>ID:</b> {{ item.id }}
          </p>
        </div>
      </div>

      <div class="col-md-4 col-lg-4 col-xl-4 col-sm-12 mb-3 text-right">
        <div class="row height-20-per">
          <h6 class="infoTextBox">{{ keeperBoxText }}</h6>
        </div>
        <div class="d-flex flex-column mb-2 pull-right">
          <img class="responsive" style="max-width: 268px" :src="keeperThumbImgPath" :alt="keeperThumbName" />
          <p style="margin-top: -15%; margin-bottom: -0.5%; margin-right: -10%">{{ keeperThumbName }}</p>
          <button class="becomeSKBtnBase becomeKeeper text-uppercase" @click="goToRouter('keeper')">
            {{ $t('miracle_deal.be_s_or_k', { s_or_k: 'Keeper' }) }}
          </button>
        </div>
        <div
          class="bg-transparent text-center pull-right w-100 pb-1"
          v-if="showTopSKButtons"
          :style="{
            'background-image': `url(${triangleImg2Path})`,
            'background-position': 'top',
            'background-repeat': 'no-repeat',
            'background-size': '32px 45px'
          }"
        >
          <h4 style="color: #77519c" class="pt-1">Top 3 Keeper</h4>
          <span class="font-weight-bold font-large-1" style="color: #77519c">
            1. 2. 3.
          </span>
          <p
            v-for="(item, idx) in topKeepers"
            :key="`topKeeper-${idx}-${item.id}`"
          >
            <b>ID:</b> {{ item.id }}
          </p>
        </div>
      </div>
    </div>
    <img class="mdealLogo" :src="mdealLogoPath" v-if="showMdealLogo" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'IntroBanner',
  components: {},
  props: {
    slogan: {
      type: String,
      default: ''
    },
    slogan2: {
      type: String,
      default: ''
    },
    strikerBoxText: {
      type: String,
      default: ''
    },
    strikerThumbImgPath: {
      type: String,
      default: require('@/assets/images/deal/Tesla.png')
    },
    strikerThumbName: {
      type: String,
      default: ''
    },

    keeperBoxText: {
      type: String,
      default: ''
    },
    keeperThumbImgPath: {
      type: String,
      default: require('@/assets/images/deal/Edison.png')
    },
    keeperThumbName: {
      type: String,
      default: ''
    },
    showTopSKButtons: {
      type: Boolean,
      default: true
    },
    showMdealLogo: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      topStrikers: (state) => state.striker.topStrikers.length > 0 && state.striker.topStrikers.slice(0,3),
      topKeepers: (state) => state.keeper.topKeepers.length > 0 && state.keeper.topKeepers.slice(0,3)
    }),
  },
  data() {
    return {
      triangleImg1Path: require('@/assets/images/deal/triangle-1.png'),
      triangleImg2Path: require('@/assets/images/deal/triangle-2.png'),
      mdealLogoPath: require('@/assets/images/deal/mdeal-logo.png')
    };
  },
  methods: {
    goToRouter(routerName) {
      this.$router.push({ name: routerName });
    }
  },
  created() {
    this.$store.dispatch('striker/FETCH_TOP_STRIKERS');
    this.$store.dispatch('keeper/FETCH_TOP_KEEPERS');
  }
};
</script>
<style lang="scss" scoped>
.polygonContainer {
  position: absolute;
  width: 100%;
  height: 260px;
  background-color: #77519c;
  margin-top: 120px;
  /* background-size: cover; */
  div {
    h1 {
      font-size: 1.7rem;
    }
  }
}
.skWrapper {
  margin-bottom: 5%;
}
.responsive {
  width: 100%;
  height: auto;
}
.infoTextBox {
  height: 100px;
  max-height: 120px;
  border-style: solid;
  border-color: #77519c;
  border-width: 1px;
  border-radius: 3px;
  overflow-y: auto;
  text-align: left;
  padding: 1rem;
}
.becomeSKBtnBase {
  background-color: #77519c;
  color: white;
  padding: 1rem;
  font-size: 2rem;
  border-width: 0;
}

.becomeStriker {
  transform: rotateY(20deg);
  -moz-transform: rotateY(20deg);
  -ms-transform: rotateY(20deg);
  -o-transform: rotateY(20deg);
  -webkit-transform: rotateY(20deg);
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
}
.becomeStriker:hover {
  transform: rotateY(10deg);
  -moz-transform: rotateY(10deg);
  -ms-transform: rotateY(10deg);
  -o-transform: rotateY(10deg);
  -webkit-transform: rotateY(10deg);
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
  cursor: pointer;
}
.becomeKeeper {
  transform: rotateY(-20deg);
  -moz-transform: rotateY(-20deg);
  -ms-transform: rotateY(-20deg);
  -o-transform: rotateY(-20deg);
  -webkit-transform: rotateY(-20deg);
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
}
.becomeKeeper:hover {
  transform: rotateY(-10deg);
  -moz-transform: rotateY(-10deg);
  -ms-transform: rotateY(-10deg);
  -o-transform: rotateY(-10deg);
  -webkit-transform: rotateY(-10deg);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
  cursor: pointer;
}

.mdealLogo {
  width: 15%;
  height: auto;
  position: absolute;
  align-self: center;
}

@media only screen and (min-width: 1000px) {
  // Laptop/desktop CSS
  .polygonContainer {
    @media only screen and (max-width: 1145px) {
      div {
        width: 20% !important;
        h1 {
          font-size: 1.4rem !important;
        }
      }
    }
  }
  .mdealLogo {
    bottom: 7%;
  }
}
@media only screen and (min-width: 280px) and (max-width: 999px) {
  // Mobile and tablet CSS

  .skWrapper {
    margin-bottom: 10%;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20%;
    }
    @media only screen and (max-width: 399px) {
      margin-bottom: 15%;
    }
  }

  .polygonContainer {
    position: relative;
    margin-bottom: 5%;
    margin-top: 10px;
    height: 150px;
    div {
      width: 100% !important;
      h1 {
        font-size: 1.5rem !important;
        @media only screen and (max-width: 500px) {
          font-size: 1.3rem !important;
        }
        @media only screen and (max-width: 400px) {
          font-size: 1.1rem !important;
        }
      }
    }
  }

  .mdealLogo {
    bottom: 8%;
    width: 25%;
    @media only screen and (max-width: 767px) {
      bottom: 2%;
      width: 30%;
    }
    @media only screen and (max-width: 399px) {
      bottom: 1%;
      width: 35%;
    }
  }
}
</style>
